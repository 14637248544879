/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext, useEffect, useState } from 'react';
import CustomWindow from 'interfaces/Utils';
import { UserContext } from 'providers/UserProvider';
import { AD_TAGS } from 'components/PaidPlacement';
import Script from 'next/script';

declare let window: CustomWindow;

export interface StnVideoProps {
  dataCy?: string;
  videoId: string;
  adTags?: {
    name: string;
    slug: string;
  }[];
}

const StnVideo = ({ videoId, adTags, dataCy = '' }: StnVideoProps) => {
  const { hasConsent, stopVideoDock } = useContext(UserContext);
  const [apiReady, setApiReady] = useState(false);
  const updateStnCid = adTags?.find((tag) => tag.name === AD_TAGS.NO_VIDEO_ADS);
  const updatedVideoId = updateStnCid ? videoId.replace(/-14497$/g, '-16481') : videoId;

  useEffect(() => {
    window.setupS2NApi = (getS2NApiForPlayer: Function) => {
      const s2nPlayer = document.querySelector('.s2nPlayer');
      window.s2nApi = getS2NApiForPlayer(s2nPlayer) as { float: boolean };
      setApiReady(true);
    };

    return () => {
      delete window.setupS2NApi;
    };
  }, []);

  useEffect(() => {
    if (apiReady) {
      window.s2nApi.float = !stopVideoDock;
    }
  }, [apiReady, stopVideoDock]);

  return hasConsent ? (
    <>
      <Script
        src={`https://embed.sendtonews.com/player3/embedcode.js?SC=${updatedVideoId}`}
        strategy='afterInteractive'
      />
      <div
        data-cy={dataCy}
        data-content-placement='stn-video'
        className={`stn-video-player s2nPlayer k-${updatedVideoId} impression-element`}
        data-type='float'
        data-s2n-api='setupS2NApi'
      />
    </>
  ) : null;
};

export default StnVideo;
