import React, { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import VodVideo from 'components/VodVideo';
import { Article } from 'interfaces/content/articles/Articles';
import StnVideo from 'components/StnVideo';
import Placeholder from 'components/Placeholder';
import { UserPaywallContext } from 'providers/PaywallProvider';
import { UserContext } from 'providers/UserProvider';
import { BrandedArticle } from 'interfaces/content/articles/Post';
import Wrapper from './RelatedVideoStyles';

interface RelatedVideoProps {
  article: Article | BrandedArticle;
}

const RelatedVideo: React.FC<RelatedVideoProps> = ({ article }) => {
  const { paywallCheck } = useContext(UserPaywallContext).paywallState;
  const [displayVideo, setDisplayVideo] = useState(false);
  const [vodRef, inViewPort] = useInView({ threshold: 0 });
  const noPaywall = paywallCheck === 'no-paywall';
  const { hasConsent } = useContext(UserContext);
  const { adTags } = article;

  useEffect(() => {
    if (inViewPort && noPaywall) {
      setDisplayVideo(true);
    } else if (!noPaywall) {
      setDisplayVideo(false);
    }
  }, [inViewPort, noPaywall]);

  const isStnVideo = article.relatedVideoType === 'stn_video_media';

  return (
    <div
      className={`related-video-container${noPaywall && hasConsent ? ' ' : ' hidden'}`}
      ref={vodRef}
    >
      {displayVideo ? (
        <>
          <h2>Related Video</h2>
          <hr />
          {isStnVideo ? (
            <StnVideo
              videoId={article.relatedVideoId}
              adTags={adTags}
            />
          ) : (
            <VodVideo
              videoId={article.relatedVideoId}
              disableAutoplay={false}
            />
          )}
        </>
      ) : (
        <Wrapper>
          <Placeholder size={{ height: 64, width: 180 }} />
          <Placeholder
            className='video-placeholder'
            size={{ height: '100%', maxWidth: 745, minHeight: 220, width: '100%' }}
          />
        </Wrapper>
      )}
    </div>
  );
};

export default RelatedVideo;
